<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="4"
      >
        <base-material-card color="#174282">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Inscription
            </div>
          </template>

          <v-form
            v-model="valid"
            ref="form"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    label="Prénom"
                    class="purple-input"
                    v-model="name"
                    color="#174282"
                  />
                  <v-text-field
                    label="Nom"
                    class="purple-input"
                    v-model="surname"
                    color="#174282"
                    required
                  />
                  <v-text-field
                    label="Adresse e-mail"
                    class="purple-input"
                    v-model="email"
                    type="email"
                    color="#174282"
                    :rules="[rules.validEmail, rules.emailAvailable, rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    label="Mot de passe"
                    class="purple-input"
                    v-model="password1"
                    color="#174282"
                    :rules="[rules.required, rules.min]"
                    :type="password"
                  />
                  <v-text-field
                    label="Confirmer le mot de passe"
                    class="purple-input"
                    v-model="password2"
                    color="#174282"
                    :rules="[rules.required, rules.match]"
                    :type="password"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="#174282"
                    class="mr-0"
                    :disabled="!valid"
                    @click="register"
                  >Inscription</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  class="text-center"
                >
                <v-card-text>
                  <em>Les données transmises pour la création de votre compte sont strictement confidentielles et ne seront communiquées à aucun tiers.</em>
                </v-card-text>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <hr/>
    <v-row >
      <v-col cols="4" md="4" style="text-align: center">
          Organisé par <a href="https://hors-pair.com/">Hors-Pair</a>
      </v-col>
      <v-col cols="4" offset-md="4" md="4" style="text-align: center">
          Powered by <a href="https://www.kertios.com">Kertios</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { httpPost } from '@/plugins/axios'
  export default {
    name: 'SignIn',
    data () {
      return {
        valid: true,
        emailExist: '',
        email: '',
        name: '',
        surname: '',
        show1: false,
        show2: true,
        password: 'Password',
        password1: '',
        password2: '',
        rules: {
          required: value => !!value || 'Requis.',
          min: v => v.length >= 8 || '8 charactères minimum',
          match: value => this.password1 === value || 'Les mots de passe ne correspondent pas',
          validEmail: value => /.+@.+\..+/.test(value) || 'E-mail non valide',
          emailAvailable: value => value !== this.emailExist || 'Cet E-mail est déjà utilisé',
        },
      }
    },
    methods: {
      async register () {
        const response = await httpPost('createUserForm', {
          name: this.name,
          surname: this.surname,
          email: this.email,
          password: this.password1,
        })
        if (response.EmailUsed !== undefined) {
          this.valid = false
          this.emailExist = this.email
          this.$refs.form.validate()
        } else if (response.Result === 'OK' && response.UserID) {
          this.$store.dispatch('modifyUser', {
            id: response.UserID,
            name: this.name,
            lastName: this.surname,
            email: this.email,
            isAdmin: false,
          })
          this.$router.push('/')
        }
      },
    },
  }
</script>
